<template>
  <!--begin::Card-->
  <div style="">
    <h4 class="mb-10 font-weight-bold text-dark">
      Setup Your Contact Details <br />
      <small
        >The Name, Email, Telephone, Address and Postcode entered in this
        section will show on your website. Make sure
        these are correct as customers may use them to contact you.</small
      >
    </h4>
    <form
      class="form"
      novalidate="novalidate"
      id="contact_form"
      ref="form"
      v-on:submit="save"
    >
      <div class="form-group">
        <label>Business Name</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="toggleName" @mouseout="toggleName"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:80px;" v-show="nameImg" :src="imgURL +'IconImages/name.png'" alt="image">
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="business_name"
          placeholder="Business Name"
          v-model="form.name"
          ref="business_name"
        />
        <span class="form-text text-muted"
          >Please enter your Bussiness Name.</span
        >
      </div>
      <div class="form-group">
        <label>Business Email</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="business_email"
          placeholder="Business Email"
          v-model="email"
          disabled
          ref="business_email"
        />
        <span class="form-text text-muted"
          >Please enter your Business Email.</span
        >
      </div>
      <div class="form-group">
        <label>Orders Notification Email</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="orders_email"
          placeholder="Orders Notification Email"
          v-model="form.orders_email"
          
          ref="orders_email"
        />
        <span class="form-text text-muted"
          >Please enter your Business Email.</span
        >
      </div>
      <div class="form-group">
        <label>Telephone / Mobile</label>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="togglePhone" @mouseout="togglePhone"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:80px;" v-show="phoneImg" :src="imgURL +'IconImages/phone.png'" alt="image">
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="business_phone"
          placeholder="Business Phone Number"
          v-model="form.phone"
        />
        <span class="form-text text-muted"
          >Please enter your Business Phone Number.</span
        >
      </div>
      <div class="form-group">
        <label>Address</label> <span>( {{ form.address }} )</span>
        &nbsp;&nbsp;
        <i class="wizard-icon flaticon-questions-circular-button" @mouseover="toggleAddress" @mouseout="toggleAddress"></i>
        <img style="border: 1px solid black;margin-left:10px;z-index: 99999999; position: absolute;height:80px;" v-show="addressImg" :src="imgURL +'IconImages/address.png'" alt="image">
        <GmapAutocomplete
          style="width:100%"
          class="form-control form-control-solid form-control-lg"
          name="business_address"
          placeholder="Business Address"
          @place_changed="setPlace"
        />
        <span class="form-text text-muted"
          >Please enter your Business Address.</span
        >
      </div>
      <div class="form-group">
        <label>Postal Code</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          placeholder="Business Postal Code"
          v-model="form.postal_code"
          name="business_postal_code"
        />
        <span class="form-text text-muted"
          >Please enter your Business Postal Code.</span
        >
      </div>
      <div class="form-group">
        <label>Google Place Id</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          placeholder="Business Postal Code"
          v-model="form.place_id"
          name="business_postal_code"
        />
        <span class="form-text text-muted"
          >Please enter your Business Google Place ID.</span
        >
      </div>
      <button
      v-if=" $hasPermission('manage system settings') " 
        :style="{'display': $route.name === 'wizard-contact-details' ? 'block' : 'none'}"
        ref="form_submit"
        class="btn btn-primary mt-3 mr-4"
      >
        Save
      </button>
    </form>
  </div>
  <!--end::Card-->
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
.pac-target-input {
  padding: 10px;
  width: 20%;
}
.btn {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: greenyellow;
}
</style>
<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import { loadGmapApi } from "vue2-google-maps";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "ContactInfo",
  data() {
    return {
      currentPlace: null,
      nameImg : false,
      phoneImg : false,
      addressImg : false,
      email : '',
      form: {
        lat: 0.0,
        lng: 0.0,
        name: "",
        phone: "",
        address: "",
        postal_code: "",
        orders_email:"",
        place_id:""
      },
      isValid: false
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    imgURL ()
    {
      return process.env.VUE_APP_API_BASE_URL;
    }
  },
  beforeMount() {
    ApiService.get("business/settings/get-general-settings")
      .then(({ data }) => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].key == "google_map_key") {
            loadGmapApi({
              key: data[i].value,
              libraries: "places"
            });
            break;
          }
        }
      })
      .catch(({ response }) => {});
  },
  mounted() {
    this.geolocate();
    this.get();
    const form = KTUtil.getById("contact_form");
    this.fv = formValidation(form, {
      fields: {
        form: {
          name: {
            validators: {
              notEmpty: {
                message: "Business Name is required"
              }
            }
          },
          email: {
            validators: {
              notEmpty: {
                message: "Email is required"
              },
              emailAddress: {
                message: "The value is not a valid email address"
              }
            }
          },
          phone: {
            validators: {
              notEmpty: {
                message: "Phone is required"
              }
            }
          },
          address: {
            validators: {
              notEmpty: {
                message: "Address is required"
              }
            }
          },
          postal_code: {
            validators: {
              notEmpty: {
                message: "Postal Code is required"
              }
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
    //console.log(this.fv.on("core.form.valid"));
    this.fv.on("core.form.valid", () => {
      this.$emit("validation", true);
    });

    this.fv.on("core.form.invalid", () => {
      this.$emit("validation", false);
    });
  },
  methods: {
    toggleName()
    {
      this.nameImg = !this.nameImg
    },
    togglePhone()
    {
      this.phoneImg = !this.phoneImg
    },
    toggleAddress()
    {
      this.addressImg = !this.addressImg
    },
    setPlace(place) {
      this.currentPlace = place;
      this.form.lat = this.currentPlace.geometry.location.lat();
      this.form.lng = this.currentPlace.geometry.location.lng();
      this.form.address = this.currentPlace.formatted_address;
      this.form.postal_code = this.fetchPostalCode(
        this.currentPlace.address_components
      );
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.form.lat = position.coords.latitude;
        this.form.lng = position.coords.longitude;
      });
    },
    fetchPostalCode(addressComponents) {
      var postal = "";
      addressComponents.forEach(d => {
        d.types.forEach(t => {
          if (t == "postal_code") {
            postal = d.long_name;
            return;
          }
        });
      });
      return postal;
    },
    get() {
      ApiService.get("business/settings/wizard", "contact-details")
        .then(({ data }) => {
          this.form = data.data;
          this.email = data.data.email;
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    },
    save() {
      let currentUserId = this.currentUser.id;
      ApiService.post(
        "business/settings/wizard/store/contact-details/" + currentUserId,
        this.form
      )
        .then(({ data }) => {
          sessionStorage.removeItem("personalInfo");
          this.$store.dispatch(UPDATE_PERSONAL_INFO);
          if  (this.$route.name === 'wizard-contact-details') {
            Swal.fire("Success", "Business Info updated successfully", "success");
          }
          return true;
        })
        .catch(({ response }) => {
          Swal.fire("Error!", response.data.message, "danger");
          return false;
        });
    },
    onSubmit() {
      if ((this.form.lat == 0 || this.form.lat == '' || this.form.lat == null) || (this.form.lng == 0 || this.form.lng == ''|| this.form.lng == null))
      {
        Swal.fire("Error!", "Address is Invalid", "danger");
        return;
      }
      if (this.form.name == '' || this.form.email == '' || this.form.phone == '' || this.form.postal_code == '' || this.form.address == '' || (this.form.lat == 0 || this.form.lat == '' || this.form.lat == null) || (this.form.lng == 0 || this.form.lng == ''|| this.form.lng == null))
      {
        Swal.fire("Error!", "All fields are required", "danger");
        return;
      }
      else {
        this.$refs.form_submit.click();
      }
    }
  }
};
</script>
